import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import { client, urlFor } from "../../client";
import "./Results.scss";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

Modal.setAppElement("#root");

const NextArrow = ({ onClick }) => (
  <div
    className='app__results-arrow app__results-arrow-right'
    onClick={onClick}
  >
    <FaArrowRight />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className='app__results-arrow app__results-arrow-left'
    onClick={onClick}
  >
    <FaArrowLeft />
  </div>
);

const Results = () => {
  const [results, setResults] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const query = '*[_type == "results"]';
    client.fetch(query).then((data) => {
      setResults(data);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const openModal = (imgUrl) => {
    setSelectedImage(imgUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className='app__results'>
      <h2 className='head-text'>Trading Results</h2>
      <Slider {...settings}>
        {results.map((result, index) => (
          <div
            className='app__results-item'
            key={result.title + index}
            onDoubleClick={() => openModal(result.imgUrl)}
          >
            <img
              src={urlFor(result.imgUrl)}
              alt={result.title}
            />
            <h2 className='bold-text'>{result.title}</h2>
            <p className='p-text'>{result.description}</p>
            <p className='double-click-text'>
              Double-click to preview
            </p>
          </div>
        ))}
      </Slider>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Full Image'
        className='app__results-modal'
        overlayClassName='app__results-overlay'
      >
        <button
          className='app__results-modal-close'
          onClick={closeModal}
        >
          <h3 style={{ marginBottom: "25px" }}>
            <u>Close</u>
          </h3>
        </button>
        <img
          src={urlFor(selectedImage)}
          alt='Full Image'
          className='modal-image'
        />
      </Modal>
    </div>
  );
};

export default Results;
