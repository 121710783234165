
import email from "../assets/email.png";
import mobile from "../assets/mobile.png";


import about01 from "../assets/about01.jpg";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import forex from "../assets/forex.jpg";
import community from "../assets/community.jpg";
import BFP from "../assets/BFP.jpg";
import MAVEN from "../assets/MAVEN.jpg";
import git from "../assets/git.png";
import candle from "../assets/candle.png";
import hedge from "../assets/hedge.png";
import fa from "../assets/fa.png";
import think from "../assets/think.png";
import vs from "../assets/vs.png";
import flutter from "../assets/flutter.png";
import bgWhite from "../assets/bgWhite.png";
import bgIMG from "../assets/bgIMG.png";
import pay from "../assets/10k pay.jpeg"

import nizzy2 from "../assets/nizzy2.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";
import nizzy from "../assets/nizzy.png";
import nizzy6 from "../assets/nizzy6.png";


// eslint-disable-next-line
export default {
  nizzy6,
  email,
  nizzy,
  bgWhite,
  bgIMG,
  mobile,
  git,
  BFP,
  MAVEN,
  community,
  forex,
  pay,
  about01,
  about02,
  about03,
  nizzy2,
  circle,
  logo,
  candle,
  hedge,
  fa,
  think,
  vs,
  flutter,
};

