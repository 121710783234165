import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
// eslint-disable-next-line

import "./About.scss";
import { urlFor, client } from "../../client";

const About = () => {
  const [about, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className='head-text'>
        As a<span> Forex Trader </span>
        I understand that <br />
        <span> Risk Management </span>
        is crucial,
        <span> Patience </span>
        is key, and
        <span> Analysis </span>
        is essential.
      </h2>

      <div className='app__profiles'>
        {about.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className='app__profile-item'
            key={about.title + index}
          >
            <img
              src={urlFor(about.imgUrl)}
              alt={about.title}
            />
            <h2
              className='bold-text'
              style={{ marginTop: 20 }}
            >
              {about.title}
              <p
                className='p-text'
                style={{ marginTop: 10 }}
              >
                {about.description}{" "}
              </p>
            </h2>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
