import React from "react";
import {
  About,
  Footer,
  Header,
  Skills,
  Testimonials,
  Results,
  Work,
} from "./container";
import { Navbar } from "./components";
import "./App.scss";

const App = () => {
  const workRef = React.createRef(); // Create a reference for Work component

  return (
    <div className='app'>
      <Navbar workRef={workRef} />{" "}
      {/* Pass the reference to Navbar */}
      <Header />
      <About />
      <div className="results">
        <Results />
      </div>
      <Work ref={workRef} />{" "}
      {/* Attach the reference to Work component */}
      <Skills />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default App;
